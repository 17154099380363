.advantage-card {
  .advantage-card-wrapper {
    display:flex;
    z-index: 1;
    &:nth-child(3),&:nth-child(4),&:nth-child(7),&:nth-child(8) {
      justify-content: flex-end;
    }
  }
  .advantage-card-content {
    @media all and (min-width: $breakpoint-small) {
      max-width: 80%;
    }
    img {
      border-radius: 20px;
    }
  }
}
.uk-position-advantage {
  position: absolute;
  left: -50px;
  width: 1000px;
  top: -185px;
  height: 300px;
  @media all and (min-width: $breakpoint-medium) {
    top: -270px;
    height: 500px;
  }

}

.teaser-image-text {
  img {
    border-radius: 50%;
  }
}
