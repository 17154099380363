.study-table {
  border-collapse: separate;
  tr {
    position: relative;

    td {
      padding: 20px 40px;
      &:first-child {
        position: absolute;
        left: -150px;
      }
      &:not(:first-child) {
        border-left: 1px solid $grey;
        border-right: 1px solid $grey;
      }
      &.uk-table-shrink {
        border: none;
      }
      &.table-border-top {
        border-top: 1px solid $grey;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
        padding: 40px 40px 0 40px;
      }
      &.table-border-bottom {
        border-bottom: 1px solid $grey;
        border-bottom-right-radius: 20px;
        border-bottom-left-radius: 20px;
        padding: 40px;
      }
    }
  }

  img {
    width: auto;
    max-height: 95px;
  }
  .border-top {
    border-top: 1px solid $grey;
    padding-top: 15px;
  }
}

.study-table-mobile {
  img {
    width: auto;
    max-height: 95px;
  }
}
