.uk-module {
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;

  .uk-module-header {
    height: 100px;
  }

  &.uk-section-primary-light {
    .uk-dynamic-headline {
      color: $light-blue;
    }
  }

  &.uk-section-green {
    .uk-dynamic-headline {
      color: $green;
    }
  }

  .uk-dynamic-headline {
    color: $blue;
  }

  @media all and (min-width: $breakpoint-medium) {
    .uk-child-width-1-2\@m {
      > div:nth-child(odd) {
        .uk-module-content {
          margin-right: 7px;
        }
      }
      > div:nth-child(even) {
        .uk-module-content {
          margin-left: 7px;
        }
      }
    }
  }

  .uk-module-content {
    margin-bottom: 30px;
    padding: 20px 30px;
    height: 100%;
    min-height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    &.pointer {
      cursor: pointer;
    }
  }
}
