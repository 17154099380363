.uk-breadcrumb > :nth-child(n+2):not(.uk-first-column)::before {
  font-size: 1.45rem;
  color: #A3A3A3;
  content: "\203A";
}

.uk-breadcrumb {
  a {
    color: #a3a3a3;
  }

  li {
    &:last-child {
      a {
        color: #6d6d6d;
      }
    }
  }
}

.uk-margin-left-30 {
  margin-left: 30px;
}
