@mixin hook-base-body() {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
		letter-spacing: 0.6px;

    @media (min-width: $breakpoint-large) {
        font-size: $base-body-font-size-l;
    }
}

/*
@mixin hook-base-heading() {
	word-break: break-word;
}*/