#application_form {
  input, select, textarea {
    background: $white;
    border: 1px solid transparentize($dark-grey, .5);
    color: transparentize($dark-grey, .15);
    padding: 10px;
    font-size: 14px;
    border-radius: 6px;
    @media all and (min-width: $breakpoint-medium) {
      font-size: 18px;
      padding: 14px;
    }

    &:focus-visible, &:focus {
      color: $blue;
      background: transparentize($light-blue, .95);
      outline: none;
      border: 1px solid $blue;
    }
    &::placeholder {
      color: transparentize($dark-grey, .5);
    }
  }

  .custom-width-3-2 {
    .form-group {
      &:nth-child(1) {
        width: 66%;
      }

      &:nth-child(2) {
        flex: 1;
        min-width: 1px;
      }
    }
  }
}
#yform-formular-dataPolicy {
  margin: 20px 0;
  @media all and (min-width: $breakpoint-medium){
    margin: 40px 0;
    max-width: 66%;
  }
  label {
    display: flex;
    align-items: baseline;
  }
}

#yform-formular-field-52-sendbuttonformular {
  text-transform: uppercase;
  margin-top: 25px;
  &:hover {
    background: $light-blue;
  }
}

#yform-formular-upload1, #yform-formular-upload2, #yform-formular-upload3, #yform-formular-upload4, #yform-formular-upload-checkbox {
  .form-control {
    display: none;
  }
  label {
    color: $light-blue;
    font-size: 12px;
    display: flex;
    align-items: baseline;
    border: 1px solid $light-blue;
    padding: 5px 10px;
    justify-content: center;
    border-radius: 25px;
    word-break: break-all;
    @media all and (min-width: $breakpoint-medium) {
      font-size: 16px;
      padding: 8px 20px;
    }
    &:hover {
      cursor: pointer;
      background: transparentize($light-blue, .95);
    }

    .uk-icon {
      padding-right: 10px;
      min-width: 20px;
    }
  }
}

#yform-formular-upload-checkbox {
  label {
    margin-top: 10px;
    color: $blue;
    border-color: $blue;
    &:hover {
      background: transparentize($blue, .95);
    }
  }
}
