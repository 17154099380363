/*
 *  Add icon to accordion title - !!! NICHT LÖSCHEN !!!
 */
.uk-accordion-title::after {
    content: "";
    width: 1.4em;
    height: 1.4em;
    float: right;
    background-image: url("data:image/svg+xml;charset=UTF-8,<svg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'> <rect x='9' y='1' width='1' height='17'></rect> <rect x='1' y='9' width='17' height='1'></rect></svg>");
    background-repeat: no-repeat;
    background-position: 50% 50%;
}

.uk-open > .uk-accordion-title::after {
    background-image: url("data:image/svg+xml;charset=UTF-8,<svg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'> <rect height='1' width='18' y='9' x='1'></rect></svg>");
}