.uk-icon-medium {
  height: 75px;
}
.card-icon {
  .uk-icon-big {
    img {
      height: 50px;
      max-width: 60px;
    }
  }

  &.is-medium {
    height: 80px;
  }

  .uk-icon-small {
    img {
      width: 50px;
      height: 50px;
      border: 3px solid $blue;
      border-radius: 50%;
      padding: 10px;
    }
  }
  img {
    height: 25px;
    max-width: 60px;
  }
}

.uk-card-title {
  margin-top: 0;
}

.uk-card-default {
  background: $white;
  border: 1px solid $grey;
  border-radius: 20px;

  .uk-card-body {
    padding: 0 40px;
  }

  &.uk-card-hover {
    &:hover {
      background: $white;
      box-shadow: 0 0 3px 1px $grey;
    }
  }
}
.cardBlock {
  height: 100%;
  .uk-card-title {
    hyphens: auto;
  }
}
.target-card {
  .uk-position-card {
    position: absolute;
    top: -39%;
    left: 0;
    right: 0;
  }

  img {
    max-width: 230px;
    max-height: 165px;
  }

  @media all and (min-width: 1200px) {
    .uk-position-card {
      top: -30%;
    }
    img {
      max-width: none;
      max-height: none;
    }
  }
}


.uk-placeholder-card {
  padding: 30px;
  @media all and (min-width: $breakpoint-large) {
    display: none;
  }
}

.contact-person {
  img {
    max-width: 110px;
    max-height: 110px;
    width: 100%;
    height: 110px;
    border-radius: 50%;
    object-fit: cover;
    @media all and (min-width: 1200px) {
      max-width: 275px;
      max-height: 275px;
      height: 275px;
      width: 275px;
    }
  }

  .uk-position-card {
    position: absolute;
    top: -33%;
    left: 0;
    right: 0;
    @media all and (min-width: 1200px) {
      top: -50%;

    }
  }
}

.customer-card {
  .uk-card-customer {
    .uk-card-header {
      padding: 0;
      margin: 0;
      height: 75px;

      img {
        border-radius: 20px;
        z-index: 2;
        object-fit: contain;
      }
    }

    .uk-card-customer-heading {
      border-radius: 20px;
      background: transparentize($light-blue, .9);
      position: absolute;
      top: 60px;
      width: auto;
      padding-bottom: 12px;
      z-index: -1;
      letter-spacing: 3px;

      .uk-padding-card-custom {
        padding: 70px;
      }
    }

    .uk-card-body {
      margin-top: 20px;
    }
  }
}

.slider-arrow {
  color: $light-blue;

  svg {
    height: 40px !important;
    width: 20px !important;
  }

  &:hover, &:active {
    color: $blue;
  }

  //&:focus {
  //  display: none;
  //}
}

.card-icon-2 {
  margin: auto 0;
  border-radius: 50%;

  img, svg {
    height: 50px;
    width: 50px;
  }
}

.uk-padding-top-10 {
  padding-top: 10px;
}

.uk-padding-left-15 {
  padding-left: 15px;
}

.uk-advantage-card {
  background: transparentize($green, .9);
  border-radius: 20px;

  .uk-advantage-card-top {
    border-bottom: 5px solid $white;
  }

  .uk-advantage-card-bottom {
    ul {
      margin-left: 40px;

      li {
        color: $blue;

        &:before {
          content: "⅃";
          font-weight: bold;
          color: $green;
          transform: rotate(45deg);
          top: -6px;
          left: -45px;
        }
      }
    }

  }
}

@media all and (max-width: $breakpoint-small) {
  .uk-advantage-card {
    .uk-advantage-card-top {
      h1 {
        font-size: 2rem;
      }
    }

    .uk-advantage-card-bottom {
      ul {
        li {
          font-size: 18px;
        }
      }

    }
  }
}

.uk-card-gray {
  background: transparentize($dark-grey, .9);
  @media all and (min-width: $breakpoint-medium) {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }
}

.uk-card-blue {
  background: transparentize($light-blue, .9);
  @media all and (min-width: $breakpoint-medium) {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
}

.uk-card-green {
  background: $light-green;

}

@media all and (min-width: $breakpoint-medium) {
  .uk-card-image-text {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    background: $white;

    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 40px;
      background: #e4eef8;
      bottom: 0;
      border-radius: 0 20px 20px 0;
    }
  }
  .uk-card-gray {
    margin-left: 20px;
  }
}

.uk-background-custom-card {
  height: 500px;
  width: 900px;
  position: absolute;
  top: 15%;
  left: 50%;
  z-index: 0;
  transform: translate(-50%);
}

.uk-card-news {
  display: flex;
  flex-wrap: wrap;

  .uk-card-news-footer {
    display: flex;
    flex-wrap: wrap;
    align-self: flex-end;
    width: 100%;
  }

  .uk-button {
    &.uk-button-primary {
      &:hover {
        background: $light-blue;
      }
    }
  }
  &-content {
    h3 {
      hyphens: auto;
    }
  }
}

.card-read-more, .card-more-autors {
  span {
    &.uk-icon {
      transition: 0.3s ease-out;
      padding-left: 2px;
      vertical-align: text-bottom;
      margin-bottom: 1px;
    }
  }

  &:hover {
    color: $green;

    span {
      &.uk-icon {
        padding-left: 12px;
        cursor: pointer;
      }
    }
  }
}


.card-hover-up {
  position: relative;
  transition: all .3s ease-out;

  a {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
  }

  &:hover {
    transform: translateY(-15px);
  }
}

.uk-custom-order {
  &:nth-child(odd) {
    text-align: right;
  }
  &:nth-child(even) {
    text-align: left;
  }
}

/*.uk-masonry-card {
  &:nth-child(2) {
    margin-top: 200px;
  }
}*/

@media all and (max-width: $breakpoint-small){
  .uk-card-header,
  .uk-card-footer,
  .uk-card-body {
      padding: 20px 0;
  }
}
