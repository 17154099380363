/* PREVENT PAGE SCROLLING ON OFF CANVAS
 ========================================================================== */
.uk-offcanvas-page {
  overflow: hidden;
}

/* RESPONSIVE YOUTUBE EMBED
 ========================================================================== */
.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


/* REDAXO DEBUG PANEL
 ========================================================================== */
.rex-var-dumper {
  :not(pre) > samp {
    white-space: pre;
  }
}

/* CUSTOM GLOBAL
 ========================================================================== */
.full-height {
  height: 100vh;
  overflow: hidden;
  margin: 0;
}

a:hover,
.uk-link:hover,
.uk-link-toggle:hover .uk-link,
.uk-link-toggle:focus .uk-link {
  color: $light-blue;
  text-decoration: underline;
}

.uk-height {
  &-100 {
    height: 100vh !important;
    object-fit: cover;
  }

  &-50 {
    height: 50vh !important;
    object-fit: cover;
  }
}

a {
  &:hover {
    text-decoration: none;
  }
}

.site-wrapper {
  overflow-x: hidden;
}

.uk-flex-end {
  justify-content: center;
  @media all and (min-width: $breakpoint-medium) {
    justify-content: flex-end;
  }
}

.uk-align-middle {
  text-align: center;
  @media all and (min-width: $breakpoint-medium) {
    text-align: left;
    margin: auto 0;
  }
}

.align-bottom {
  height: 100%;
  display: flex;
  align-items: flex-end;
}

[class*='position-top'],
[class*='position-bottom'],
[class*='position-left'],
[class*='position-right'],
[class*='position-center'] {
  position: absolute;
  max-width: 100%;
}


.position {
  &-top-left {
    top: 0;
    left: 0;
  }

  &-top-right {
    top: 0;
    right: 0;
  }

  &-bottom-left {
    bottom: 0;
    left: 0;
  }

  &-bottom-right {
    right: 0;
    bottom: 0;
  }
}


.uk-height-maps {
  height: 400px;
  @media all and (min-width: $breakpoint-medium) {
    height: 800px;
  }
}

.uk-vertical-center {
  align-self: center;
}

.uk-grid-xsmall > * {
  padding-left: 5px;
}

body {
  background: $white;
}

hr {
  margin: 10px 0px;
}

.filterList {
  list-style: none;
  margin: 0;
  padding: 0;
  gap: 10px;
  .blogFilter {
    transition: all .3s ease-in-out;
    font-size: 15px;
    letter-spacing: .5px;
    padding: 8px 15px;
  }
}

.productList {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  img {
    width: 120px;
    height: 120px;
    @media (min-width: $breakpoint-medium) {
      width: 200px;
      height: 200px;
    }
  }
}
.hover-content-wrapper {
  height: 100%;
  .uk-padding.padding-break-large {
    padding: 10px !important;
  }
  .headline {
    line-height: 1.3 !important;
    font-size: rem(21px) !important;
  }
  h3 {
    line-height: 1.3 !important;
    font-size: rem(21px) !important;
  }
}
.justify-center {
  justify-content: center;
}

.search_it-results li {
  border-bottom: 1px solid #ddd;
  padding-bottom: 20px;
  margin-bottom: 20px;
  &:last-child {
    border-bottom: none;
  }
}


.search_it-title {
  a {
    font-size: 21px;
    color: $green;
    font-weight: 700;
    letter-spacing: 0.33px;
    transition: all .3s ease-in-out;
    &:hover {
      color: $blue;
    }
  }
}