@mixin font-face($family,$src,$style: normal,$weight: normal) {
  @font-face {
    font-family: $family;
    font-style: $style;
    font-weight: $weight;
    src: url('#{$src}.eot');
    src: url('#{$src}.eot?#iefix') format('embedded-opentype'),
    url('#{$src}.woff2') format('woff2'),
    url('#{$src}.woff') format('woff'),
    url('#{$src}.ttf') format('truetype'),
    url('#{$src}.svg##{$family}') format('svg');
    font-display: swap;
  }
}

@include font-face('Open Sans', '../fonts/open-sans/open-sans-v15-latin-italic', italic, 400);
@include font-face('Open Sans', '../fonts/open-sans/open-sans-v15-latin-regular', normal, 400);
@include font-face('Open Sans', '../fonts/open-sans/open-sans-v15-latin-700italic', italic, 700);
@include font-face('Open Sans', '../fonts/open-sans/open-sans-v15-latin-700', normal, 700);

@include font-face('Barlow', '../fonts/barlow/barlow-v5-latin-italic', italic, 400);
@include font-face('Barlow', '../fonts/barlow/barlow-v5-latin-regular', normal, 400);
@include font-face('Barlow', '../fonts/barlow/barlow-v5-latin-700italic', italic, 700);
@include font-face('Barlow', '../fonts/barlow/barlow-v5-latin-700', normal, 700);

// Custom font, color and word classes
.uk-word-break {
  word-break: break-word;
}

.uk-font {
  &-xxlarge {
    font-size: 4.5rem;
    @media all and (min-width: $breakpoint-medium) {
      font-size: 5.5rem;
    }
  }

  &-responsive {
    font-size: 1.1rem;
  }

  &-responsive-h1 {
    font-size: 1.75rem;
  }

  &-responsive-large {
    font-size: 1.1rem;
  }

  &-responsive-medium {
    font-size: 1.1rem;
  }

  &-responsive-small {
    font-size: 1.1rem;
  }

  @media all and (min-width: 600px) {
    &-responsive {
      font-size: 1.1rem;
    }
    &-responsive-large {
      font-size: 1.25rem;
    }
    &-responsive-medium {
      font-size: 1.125rem;
    }
    &-responsive-small {
      font-size: 1.1rem;
    }
  }

  @media all and (min-width: 600px) {
    &-responsive-h1 {
      font-size: 2rem;
    }
    &-responsive-large {
      font-size: 1.625rem;
    }
    &-responsive-medium {
      font-size: 1.25rem;
    }
    &-responsive-small {
      font-size: 1.125rem;
    }
  }

  @media all and (min-width: 1600px) {
    &-responsive-h1 {
      font-size: 2.5rem;
    }
  }
}
.uk-seo-text {
  font-size: 1.1rem;
}

.uk-text {
  &-white {
    color: $white;
  }

  &-black {
    color: $black;
  }

  &-grey {
    color: $dark-grey;

    &-light {
      color: transparentize($dark-grey, .5);
    }
  }

  &-green {
    color: $green;

    &-light {
      color: transparentize($green, .5);
    }
  }

  &-secondary {
    &-light {
      color: transparentize($blue, .5);
    }
  }

  &-primary {
    &-light {
      color: transparentize($light-blue, .88);
      font-size: 15rem;
      font-weight: bold;
      line-height: 1rem;
      @media all and (min-width: $breakpoint-small) {
        font-size: 20rem;
      }
    }
    &-lighter {
      color: transparentize($light-blue, .6);
    }
  }

  &-xlarge {
    font-size: 3rem;
    font-weight: bold;
  }

  &-xxlarge {
    font-size: 5rem;
    font-weight: bold;
  }

  &-xxxlarge {
    font-size: 9rem;
    line-height: 10rem;
    font-weight: bold;
  }
}

.uk-font {
  &-12 {
    font-size: rem(12px);
  }

  &-16 {
    font-size: rem(16px);
  }

  &-18 {
    font-size: rem(18px);
  }

  &-20 {
    font-size: rem(20px);
  }

  &-22 {
    font-size: rem(22px);
  }

  &-26 {
    font-size: rem(26px);
  }

  &-30 {
    font-size: rem(30px);
  }
}

.uk-letter-spacing {
  letter-spacing: 2px;
}

.muted {
  color: transparentize($white, .25) !important;
}
