.alert{
  &.alert-danger {
    padding-bottom: 20px;
  }
}
.news-button {
  @media all and (max-width: $breakpoint-small){
    padding-top: 15px;
  }
}

.uk-footer-right-left {
  text-align: left;

  @media all and (min-width: $breakpoint-small) {
    text-align: right;
  }

}

.uk-logo-footer {
  .st0 {
    fill: $white;
  }
}

.site-footer {
	h4 {
		word-break: normal;
	}
}