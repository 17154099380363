.events {
	&-notice {
		text-align: right;
		margin-bottom: 20px;

		svg {
			margin-right: 10px;
		}
	}

	.event {
		&-inner {
			background: rgba($grey, .25);
			padding: 35px 20px;
		}

		&-grid {
			> * {
				display: flex;
				align-items: center;
			}
		}

		&-amcon {
			@media (min-width: $breakpoint-large) {
				justify-content: center;
			}
		}

		&-date,
		&-name {
			font-size: rem(30px);
		}

		&-date {
			color: $light-blue;
		}

		&-name {
			color: $blue;
		}

		&-link {
			a {
				text-transform: uppercase;
			}
		}
	}
}

a {
	&.disabled {
		&:hover {
			cursor: default;
		}
	}
}


