#amcon_print {
  input {
    font-family: $font-family;
    border-radius: 5px;
    padding: 14px 17px;
    letter-spacing: 1px;
    border:1px solid darken($grey, 10%);
    font-size: $base-body-font-size;

    &::placeholder {
      color: darken($grey, 10%);
    }
  }
}
