// Kann bei Kundenseiten direkt entfernt werden
.uk-logo {
  > img,
  > svg {
    height: auto;
    width: 200px;
  }
}

.service {
  #nav-icon-hamburger {
    margin-left: 10px;
    width: 16px;
    position: relative;
    cursor: pointer;

    span {
      display: block;
      position: absolute;
      height: 2px;
      width: 100%;
      background: $blue;
      opacity: 1;
      left: 0;
      -webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out;

      &:nth-child(1) {
        top: 9px;
      }

      &:nth-child(2) {
        top: 15px;
      }

      &:nth-child(3) {
        top: 22px;
        width: 10px;
      }
    }
  }

  &:hover {
    text-decoration: none;

    #nav-icon-hamburger {
      span {
        background: $light-blue;

        &:nth-child(2) {
          width: 20px;
        }

        &:nth-child(3) {
          width: 16px;
        }
      }
    }
  }
}
