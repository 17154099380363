.uk-navbar-container {
  .navbar {
    @media all and (min-width: $breakpoint-medium) {
      padding: 22px 40px;
    }
    padding: 10px 15px;
    transition: all .2s ease-out ;
  }
  &.uk-navbar-sticky {
    .uk-container-expand {
      padding: 0 40px
    }
  }
  a {
    text-transform: uppercase;
  }
  .uk-navbar {
    a, span {
      letter-spacing: 2px;
      white-space: nowrap;
      text-decoration: none;
      @media all and (min-width: $breakpoint-medium) {
        letter-spacing: 3.3px;
      }

      &.uk-text-large {
        font-size: 1.65rem;
      }

      &.service {
        font-size: $navbar-nav-item-font-size;
      }
    }
    .uk-navbar-nav {
      .border {
        a[aria-expanded="true"]:after {
          position: absolute;
          z-index: 1040;
          bottom: -33px;
          left: 0;
          height: 10px;
          display: block;
          width: 100vw;
          background: $global-secondary-background;
          content: '';
        }
      }
    }
  }
  .uk-navbar-dropdown-nav {
    a, span {
      color: $white;
    }
    a.headline.children,
    .headline-noLink {
      padding: 5px 5px 5px 5px !important;
    }
    .childrenBlock {
      padding: 5px 5px 30px 5px !important;
      li {
        margin: 0 !important;
      }
    }
  }
  .uk-nav {
    a, span {
      padding-left: 7px;
      font-size: 16px;
      @media all and (min-width: $breakpoint-medium) {
        font-size: 18px;
      }
      &.headline {
        color: $text-color-secondary;
        font-size: $navbar-nav-item-font-size;
        font-weight: 700;
        padding-bottom: 30px;

      }
    }
  }
}

@media all and (max-width: $breakpoint-medium) {
  a {
    &:focus,
    &:hover,
    &:active {
      color: $white;
    }
  }
}


.header-top .uk-navbar-nav>li>a {
  font-size: 18px;
  min-height: unset;
}

.uk-navbar-container .uk-nav a.lev3 {
  white-space: wrap;
  letter-spacing: unset;
  text-transform: none;
}

.uk-navbar-nav>li>a {
  padding: 0 0 !important;
}
.uk-navbar-container {
  .uk-nav {
    a.headline,
    .headline-noLink {
      font-weight: 700;
      font-size: 18px !important;
      color: white !important;
      letter-spacing: 3.3px;
      text-transform: uppercase;
    }
  }
}

.uk-navbar-dropdown.uk-open {
  display: block;
}
.uk-navbar-dropdown {
  display: none;
  position: absolute;
  z-index: 999999;
  box-sizing: border-box;
  padding: 15px;
  background: #4b8ECFe6;
  color: #666;
  top: 102px !important;
}
