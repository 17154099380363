.mobile-navigation {
  overflow: auto;
  background: $offcanvas-bar-background;

  .margin-top-large {
    margin-top: 50px;

    li {
      position: relative;
    }
  }

  .toggle-right-middle {
    position: absolute;
    right: 0;
    top: 7px;
  }

  .uk-offcanvas-close {
    top: 16px;
    right: 0;
    padding: 10px;
  }
  .uk-logo-footer {
    .st1 {
      fill: $white;
    }
  }
}

@media all and (min-width: $breakpoint-medium) {
  .mobile-navigation {
    display: none;
  }
}
