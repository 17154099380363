.uk-section {
  &-blue {
    background: transparentize($blue, .5);
  }
  &-light-blue {
    background: transparentize($light-blue, .5);
  }
  &-dark-blue {
    background: transparentize($blue, .75);
  }
  &-primary-light {
    background: transparentize($light-blue, .85);
  }
  &-green {
    background: transparentize($green, .9);
  }
  &-light-green {
    background: transparentize($green, .5);
  }
  &-light-grey {
    background: transparentize($dark-grey, .5);
  }
}
