.uk-dotnav>*>* {
  width: 12px;
  height: 12px;
  background: transparent;
  border: 1px solid #9b9b9b;
}

.uk-dotnav>.uk-active>* {
  background-color: $green;
  border-color: $green;
}