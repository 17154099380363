@media all and (min-width: 1200px) {

  .path, .path2 {
    clip-path: polygon(0 33%, 87% 0%, 57% 109%, 0% 100%);
    height: 77%;

	  .uk-banner & {
		  height: 90%;
	  }
  }

  .path3, .path4 {
    clip-path: polygon(-10% 0, 75% 37%, 59% 100%, 0 100%);
    height: 70%;
    width: 57%;

	  .uk-banner & {
		  height: 90%;
	  }
  }

  .path5, .path6 {
    clip-path: polygon(0 39%, 76% 0, 54% 110%, 0 100%);
    height: 60%;

	  .uk-banner & {
		  height: 90%;
	  }
  }

  .path, .path2, .path3, .path4, .path5, .path6 {
    width: 67%;
    background: transparentize($global-primary-background, .25);

	  .uk-banner & {
		  width: 70%;
	  }

    &.position-bottom-right {
      -moz-transform: scale(-1, 1);
      -webkit-transform: scale(-1, 1);
      -o-transform: scale(-1, 1);
      -ms-transform: scale(-1, 1);
      transform: scale(-1, 1);
    }

    &.position-top-right {
      -moz-transform: scale(-1, -1);
      -webkit-transform: scale(-1, -1);
      -o-transform: scale(-1, -1);
      -ms-transform: scale(-1, -1);
      transform: scale(-1, -1);
    }

    &.position-top-left {
      -moz-transform: scale(1, -1);
      -webkit-transform: scale(1, -1);
      -o-transform: scale(1, -1);
      -ms-transform: scale(1, -1);
      transform: scale(1, -1);
    }
  }

  .path, .path5 {
    bottom: -20px;
    left: -40px;

    &.position-bottom-right {
      right: -30px;
    }

    &.position-top-right {
      bottom: auto;
      top: -20px;
      right: -30px;
    }

    &.position-top-left {
      bottom: auto;
      top: -20px;
    }
  }

  .path2, .path6 {
    bottom: -40px;
    left: 0;

    &.position-bottom-right {
      left: auto;
    }

    &.position-top-right {
      left: auto;
      bottom: auto;
      top: -40px;
    }

    &.position-top-left {
      bottom: auto;
      top: -40px;
    }
  }

  .path3 {
    bottom: -45px;

    &.position-bottom-right {
      right: -60px;
    }

    &.position-top-right {
      bottom: auto;
      top: -60px;
      right: -70px;
    }

    &.position-top-left {
      bottom: auto;
      top: -60px;
    }
  }

  .path4 {
    bottom: -15px;
    left: 40px;

    &.position-bottom-right {
      left: auto;
      right: 0;
    }

    &.position-top-right {
      left: auto;
      bottom: auto;
      top: -40px;
      right: -20px;
    }

    &.position-top-left {
      bottom: auto;
      top: -40px;
    }
  }
}

@media (min-width:$breakpoint-xxlarge) {
	.path, .path2, .path5, .path6 {
		height: 60%;

		.uk-banner & {
			height: 60%;
		}
	}

	.path3, .path4 {
		.uk-banner & {
			height: 70%;
		}
	}

	.path, .path2, .path3, .path4, .path5, .path6 {
		width: 50%;

		.uk-banner & {
			width: 50%;
		}
	}
}

.uk-triangle {
  clip-path: polygon(0 0, 100% 100%, 0 100%);
  height: 40px;
  width: 40px;
  bottom: -40px;
  @media all and (min-width: $breakpoint-medium) {
    height: 70px;
    width: 70px;
    bottom: -70px;
  }

  &.is-question {
    left: 40px;
    transform: rotate(90deg);
    @media all and (min-width: $breakpoint-medium) {
      left: 30px;
    }
  }

  &.is-answer {
    right: 40px;
    transform: rotate(180deg);
    @media all and (min-width: $breakpoint-medium) {
      right: 30px;
    }
  }
}

.uk-triangle-small {
  height: 29px;
  width: 29px;
  right: 33%;
  background: #f8f8f8;
  &:after {
    content: url("/media/ecke_zitat.svg");
    height: 29px;
    width: 29px;
  }
}
.uk-quote-divider {
  border-bottom: 1px solid $light-blue;
}

