.uk-button {
  &.uk-button-default {
    border-radius: 25px;
    padding: 13px 35px;
  }
  &.uk-button-primary {
    border-radius: 25px;
    padding: 13px 35px;
    color: $blue;
    letter-spacing: 1px;
    &:hover {
      background: $white;
    }
  }
  &.uk-button-secondary {
    border-radius: 25px;
    padding: 13px 35px;
    color: $blue;
    background: $green;
    letter-spacing: 1px;
    &:hover {
      background: $light-blue;
    }
  }
  &.uk-button-invert {
    background: $white;
    color: $light-blue;
    padding: 11px 32px 12px 32px;
    border-radius: 4px;
    &:hover {
      background: $green;
      color: $white;
    }
  }
  &.uk-button-link {
    color: $green;
    text-transform: uppercase;
    &:hover {
      color: $blue;
      text-decoration: none;
    }
    &.is-back {
      color: $blue;
      &:hover {
        color: $light-blue;
      }
    }
  }
}

.close-button {
  span {
    font-size: 12px;
    line-height: 14px;
  }
  svg {
    padding-left: 4px;
    color: $white;
    width: 18px;
  }
}

.apprentice-button {
  border-radius: 20px;
  color: $blue;
  background: $green;
  &.btn-disabled {
    pointer-events: none;
    background: transparentize($green, .5);
    color: transparentize($blue, .5);
  }
}

a {
  &.uk-button-filter {
    background: $white;
    border: 1px solid $green;
    border-radius: 25px;
    color: $blue;
    font-size: 16px;
    letter-spacing: 1px;
    padding: 7px 24px;

    &:hover {
      background: $green;
    }
    &.is--active {
      background: $green;
    }
  }
}

.totop-button {
  position: fixed;
  bottom: 0;
  right: 20px;
  z-index: 1000;
  opacity: 0;
  transform: translate(0, 20px);
  transition: all .3s ease-out;
  @media all and (min-width: $breakpoint-medium) {
    bottom: 20px;
    right: 40px;
  }

  &.visible {
    opacity: 1;
    transform: translate(0,0);
  }

  .totop-primary {
    border: 1px solid $light-blue;
    padding: 14px 10px;
    border-radius: 50%;
    color: $light-blue;
    background: $white;

    &:hover {
      background: darken($white, 10%);
    }
  }
}

