#share-panel {
  position: absolute;
  top: 0;
  left: -2%;
  transform: translateY(-93%);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.sharing-panel {
  position: absolute;
  top: 0;
  left: -2%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: auto;
}

.share-panel-action > * {
  &:hover {
    cursor: pointer;
  }
}
