@media all and (max-width: 1199px) {
  .info-container {
    background: $light-blue;
    width: 100%;
    position: static !important;
  }

.scroller {
    display: none;
  }

  .banner-slider {
    overflow-y: scroll;
    max-height: 100%;
  }
}
