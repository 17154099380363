.search_it-form {
  fieldset {
    border-radius: 30px;
    border: 1px solid $grey;
    color: $dark-grey;
    .search_it-flex {
      font-size: 20px;
      color: $dark-grey;
      .search-text {
        width: 100%;
        font-size: 18px;
        border: none;
        padding: 0 0 0 10px;
        border-radius: 26px;
        letter-spacing: 1px;
        height: 27px;
        &:focus-visible {
          outline: none;
        }
      }
      .search_it-button {
        display: none;
      }
    }
  }
}
