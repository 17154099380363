.uk-flex-list-custom {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
}

.uk-apprentice-card-bottom {
  li {
    &.check {
      display: grid;
      grid-template-columns: 32px 1fr;
      align-items: flex-start;
      gap: 10px;
      .check-icon {
        background-image: url("/media/pfad_1636.svg");
        background-size: contain;
        background-repeat: no-repeat;
        height: 1em;
        width: 1em;
        position: relative;
        top: 5px;
      }
    }
  }
}

