/* OVERWRITE UIKIT VARIABLES
 ========================================================================== */

/* TYPOGRAPHY
 ========================================================================== */
$font-family:                               Barlow, 'Open Sans', Arial, Helvetica, sans-serif;
$base-body-font-family:                     $font-family;
$navbar-nav-item-font-family:               $font-family;
$global-font-family:                        $font-family;

$base-body-font-size:                       14px;
$base-body-font-size-l:                     16px;

$navbar-nav-item-font-size:                 22px;



/*  COLORS
 ========================================================================== */
$light-blue:                                #4B8ECF;
$blue:                                      #253359;
$green:                                     #74BC47;
$light-green:                               rgba(116, 188, 71, 0.10);
$white:                                     #ffffff;
$grey:                                      #dddddd;
$dark-grey:                                 #666666;
$black:                                     #000000;

$global-primary-background:                 $light-blue;
$global-secondary-background:               $blue;
$global-link-color:                         $blue;
$text-color-secondary:                      $blue;

$base-em-color:                             $dark-grey;

/*  HEADINGS
 ========================================================================== */
$base-heading-font-family:                  $base-body-font-family;
$base-h1-font-size:                         rem(27px);

/*  BUTTONS
 ========================================================================== */
$button-line-height:                        1.3;
$button-small-line-height:                  1.3;
$button-large-line-height:                  1.2;

$button-padding-vertical:                   .5rem;

$button-primary-background:                 $green;
$button-default-background:                 $green;
$button-default-color:                      $blue;

/*  NAVBAR
 ========================================================================== */
$navbar-nav-item-color:                     $global-primary-background;
$navbar-nav-item-hover-color:               $global-secondary-background;
$navbar-default-link-hover-color:           $global-primary-background;

$navbar-dropdown-background:                #4B8ECFe6;

/*  OFFCANVAS
 ========================================================================== */
$offcanvas-bar-background:                  #009ADE;
$overlay-padding-horizontal:                40px;
$overlay-padding-vertical:                  40px;

$breakpoint-xxlarge:                        1921px !default; // xLarge Screens

$overlay-padding-horizontal:                15px;
