.uk-grey-border {
  border: 2px solid $grey;
}

.border-bottom {
  border-bottom: 1px solid $grey;
}

.uk-border-20 {
  border-radius: 20px !important;
}

.uk-border-rounded {
  border-radius: 20px;
}

.uk-padding-bottom-20 {
  padding-bottom: 20px;
}

.uk-image-rounded {
  border-radius: 20px;
}

.uk-image-circle {
  border-radius: 50%;
}

.uk-align-items-center {
  align-items: center;
}

.uk-content-space-between {
  align-content: space-between;
}

.uk-self-center {
  align-self: center;
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.uk-hyphen-auto {
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
