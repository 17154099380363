#formular {
  padding-top: 20px;
}

.order-2 {
  .form-control {
    max-width: 100%;
    width: 85%;
    padding: 13px 20px;
    border-radius: 4px;
    border: 1px solid $white;
    background: $blue;
    color: $white;
    font-size: 14px;

    &::placeholder {
      color: $white;
    }
  }
}

.uk-button-newsletter {
  background: $white;
  color: $light-blue;
  border-radius: 4px;
  padding: 13px 20px;
  font-size: 14px;

  &:hover {
    background: darken($white, 10%);
  }
}

.newsletter {
  .uk-scrollspy-inview {
    width: 100%;
  }
}
