.overlap {
  &-container {
    position: relative;

	  &:not(:last-of-type) {
		  margin-bottom: 50px;

		  @media (min-width: $breakpoint-large) {
			  margin-bottom: 0;
		  }
	  }
  }

  &-item {
    @media (min-width: $breakpoint-large) {
      position: absolute;

      &.overlap-card {
        &.overlap-top {
          bottom: 5%;
        }

        &.overlap-bottom {
          top: 5%;
        }
      }

      &.overlap-image {
        &.overlap-top {
          bottom: 15%;
        }

        &.overlap-bottom {
          top: 15%;

          &.overlap-xlarge {
            top: unset;
            bottom: -20%;
          }
        }
      }

      &.overlap-center {
        top: 50%;
        transform: translateY(-50%);
      }

      &.overlap-medium,
      &.overlap-small {
        right: 0;
      }

      &.overlap-large,
      &.overlap-xlarge {
        right: 10%;
      }
    }
  }
}

.image-container-custom {
  img {
    border-radius: 20px;
  }
}

.uk-card-custom {
  border-radius: 20px;
  background: transparentize($light-blue, .9);
}

@media all and (min-width: 1200px) {
  .overlap-container {
    .overlap-large, .overlap-xlarge {
      &:first-child {
        position: relative;
        left: 5%;
      }

      &:last-child {
        right: 5% !important;
      }
    }
  }
}

.key-custom {
  top: 65px;
  left: 0;
  @media all and (min-width: $breakpoint-small) {
    top: 25px;
  }
}

.uk-overlay {
  &.banner-small {
    padding: 20px 15px;
    @media all and (min-width: $breakpoint-large) {
      padding: 20px 20px;
    }
  }
}
