.jobs-detail {

  .uk-icon-info {
    height: 40px;
    margin-bottom: 15px;
  }

  .uk-blue-bullets {
    padding-bottom: 40px;

    ul {
      list-style: none;

      li::before {

        content: "\2022";
        color: $light-blue;
        font-size: 1.2rem;
        font-weight: bold;
        display: inline-block;
        width: 1rem;
        margin-left: -1rem;
      }
    }
  }

	.jobs-infos {
		> div {
			&:not(:nth-of-type(n+3)) {
				margin-bottom: 40px;

				@media (min-width: $breakpoint-medium) {
					margin-bottom: 0;
				}
			}
		}
	}
}

.uk-two-rows {
  .uk-job-entry {
    &:nth-child(n+3) {
      margin-top: 40px;

      > div {
        border-top: 1px solid $grey;
      }
    }
  }
}

.uk-three-rows {
  .uk-job-entry {
    &:nth-child(n) {
      margin-top: 40px;

      > div {
        border-top: 1px solid $grey;
      }
    }
  }
}



