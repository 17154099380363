.news-template {
  .left-template {
    @media all and (min-width: $breakpoint-large){
      border-right: 1px solid $grey;
      padding-right: 40px;
    }
  }
}


