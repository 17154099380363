.consent_manager-background {
  .consent_manager-wrapper {
    .consent_manager-wrapper-inner {
      .consent_manager-summary {
        #consent_manager-toggle-details {
          color: $light-blue;
          &:before {
            background-color: $light-blue;
          }
        }
      }
      .consent_manager-detail {

      }
      .consent_manager-buttons-sitelinks {
        .consent_manager-buttons {
          .consent_manager-accept-all {
            background: $light-blue;
            border-color: $light-blue;
            &:hover {
              background: $green;
              border-color: $green;
            }
          }
        }
      }
    }
  }
}
