.detail-news {
  h5 {
    color: $light-blue;
    font-size: 20px;
  }

  h3 {
    color: $blue;
  }

  img {
    border-radius: 20px;

    &.uk-image-circle {
      border-radius: 50%;
    }
  }

  .detail-news-header {
    .align-end {
      align-items: flex-end;
    }
  }

  .detail-news-content {
    img[style*="float: right;" ] {
      margin: 0 0 30px 30px !important;
    }

    img[style*="float: left;" ] {
      margin: 0 30px 30px 0 !important;
    }

    img {
      height: auto !important;
      @media all and (min-width: $breakpoint-small) {
        max-width: 50%;
      }
      @media all and (min-width: $breakpoint-medium) {
        max-width: 100%;
      }
    }
  }
}

.social-media-links {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0;
}

.uk-container {
  ul {
    &.pagination-list {
      list-style: none;
      li {
        margin-top: 0;
        &:nth-child(3) {
          margin: 2px 7px 0 5px;
        }
      }
    }

  }
}
