.banner-slider {
  background: rgba(255, 255, 255, 0.85);
  padding: 0 20px 10px 20px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}

.banner-cover {
  max-height: 750px;
  object-fit: cover;
}

.info-container {
	h1 {
		word-break: normal;
	}
  .uk-text-white {
    @media (max-width: $breakpoint-small) {
      font-size: 20px;
      line-height: 1.3;
    }
  }
}

.uk-margin-left-auto {
  margin-left: auto;
}

.uk-width-fit-content {
  width: fit-content;
}

.uk-position-bottom-left, .uk-position-top-left, .position-top-left , .position-bottom-left {
  &.info-container {
    @media all and (min-width: $breakpoint-small){
      padding-left: 40px;
    }
    @media all and (min-width: $breakpoint-medium){
      left: 20px;
    }
    @media all and (min-width: $breakpoint-large){
      padding-bottom: 25px;
      max-width: 500px;
    }
    @media all and (min-width: $breakpoint-xlarge){
      left: 50px;
    }
    @media all and (min-width: $breakpoint-xxlarge){
      left: 45px;
      max-width: 525px;
      padding: 45px 70px !important;
	    width: 28%;

	    .uk-banner-main & {
        max-width: 555px;
        padding: 45px 25px!important;
      }
    }
  }
}

.uk-position-bottom-right, .uk-position-top-right, .position-bottom-right, .position-top-right {
  &.info-container {
    @media all and (min-width: $breakpoint-large){
      left: auto;
      max-width: 550px;
    }
    @media all and (min-width: $breakpoint-xxlarge){
      max-width: 615px;
      padding: 45px 70px !important;
	    width: 25%;
    }
  }
}

.scroller {
  .uk-text-white {
    opacity: 0.7;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 3.3px;

    &:hover {
      color: $global-muted-color;
      text-decoration: none;
    }
  }
}

@media all and (min-width: $breakpoint-medium){
  .position-right-125 {
    right: 125px;
  }
}

.slider-down {
  p {
    padding: 0;
    margin: 0;
  }
}

.uk-svg-size {
  padding: 5px;
  > svg {
    width: 30px;
    height: 30px;
    &:hover {
      cursor: pointer;
    }
  }
}

